import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
    <Layout>
        <Seo title="About" />
        <h1>Who we are</h1>
        <p>
        The S2 Underground Project is a non-governmental private intelligence agency. We do not work for (or
support) any political group, nor are we affiliated with any social “movement” in any way. We do not work for any
government, nor do we seek to topple or remove any facet of government. We are a neutral organization, a collection
of current and former intelligence analysts that became friends and wanted to use our analytical skills for good. We
use investigative journalism, analytical techniques, clandestine operations, surveillance tools and a myriad of other
sources to better fight everything from governmental tyranny to terrorism. As such, we do not represent the US
Government in any way…in fact, much of our investigations do not cast certain aspects of government in a positive
light. Thus the reason for our anonymity. By remaining anonymous, we are able to continue doing good work while at
the same time being beholden to no one. We also have a large disaster relief and aid component to our organization,
which you have likely already experienced if you are reading this message. We are NOT a “survivalist” group, not by a
long shot, but recent events have indicated to us that it would be prudent to make some common-sense preparations. 
We began as a small social media account run by a few friends, but now we are a large, loosely organized collective of several thousand people,
from all walks of life. The only thing that our loosely organized members have in common is a desire for truth in an
age of unilateral disinformation. Anyone can claim to be a “member” of the S2 Underground, but we are a non-violent
organization. No threats of any kind are welcome, nor is racism, bigotry, xenophobia, or hostility of any kind. We
welcome everyone, regardless of race, religion, or creed. We firmly believe in the principle of public service and
dedication to helping others, and by creating this unique organization we have hoped to leverage our special skillset
to leave a positive impact in an unfriendly world. Thank you for taking the time to learn a bit about us, and if you
would like to contact us securely and anonymously, a Comm Card should have accompanied this leaflet. 
        </p>
    </Layout>
)

export default AboutPage
